import Link from "next/link";
import { PortableTextBlock } from "next-sanity";

import { useTrack } from "@chef/feature-tracking";

import { BlockContent } from "./BlockContent";

interface IBanner {
  _type: "banner";
  _key: string;
  body: PortableTextBlock[];
  buttonText: string;
  href: string;
}

interface BannerComponentProps {
  value: IBanner;
}

export const BannerComponent = ({ value }: BannerComponentProps) => {
  const track = useTrack();
  if (!value) {
    return null;
  }
  const handleClick = () => {
    track("buttonClicked", {
      affiliation: "Frontend process",
      button_colour: "custom_outlined",
      button_text: value.buttonText,
      button_source: "sanity",
      button_destination: value.href,
      button_placement: "banner",
      button_action: "click",
    });
  };
  return (
    <Link onClick={handleClick} href={value.href} className="no-underline">
      <div className="w-screen bg-primary stretch-x">
        <div className="flex items-center justify-between gap-6 px-4 py-3 text-white page-max-width md:py-6 ">
          <div aria-hidden="true" className="hidden md:w-32 md:block"></div>
          <div className="font-serif text-base md:text-lg md:text-center">
            <BlockContent body={value.body} prose={{ disabled: true }} />
          </div>
          <div className="md:w-32">
            <button className="p-3 text-sm bg-transparent border-2 border-solid rounded-full border-highlight text-nowrap">
              {value.buttonText}
            </button>
          </div>
        </div>
      </div>
    </Link>
  );
};
